/* eslint-disable react/display-name */
import React from "react";
import { ToolOutlined, UserOutlined } from "@ant-design/icons";
import { UPGRADE_TIER_MODAL_OBJECTS, UPGRADE_TIER_MODAL_TYPES } from "../modals/assets/upgradeTierModal.assets";

import icon_card_star from "../../public/icon-card-star.svg";
import icon_card_hand from "../../public/icon-card-hand.svg";
import icon_bell from "../../public/icon-bell.svg";
import icon_line_chart from "../../public/icon-line-chart.svg";
import icon_pricetag from "../../public/icon-pricetag.svg";
import icon_intelligence_reports from "../../public/icon-intelligence-reports.svg";
import icon_cards_for_sale from "../../public/icon-cards-for-sale.svg";
import icon_admin_a from "../../public/icon-admin-A.svg";
import icon_admin_b from "../../public/icon-admin-B.svg";
import icon_info from "../../public/icon-info.svg";
import quick_search from "../../public/quick_search.svg";
import market_pulse from "../../public/icon-marketIndex.svg";
import Helpers from "services/helpers";

import { MembershipTiers, isUserAtLeast } from "../../utils/user";

export const customHeaderIcons = {
  "card-star": { icon: icon_card_star, viewBox: "0 0 21 30" },
  "card-hand": { icon: icon_card_hand, viewBox: "0 0 30 29" },
  bell: { icon: icon_bell, viewBox: "0 0 25 29" },
  "line-chart": { icon: icon_line_chart, viewBox: "0 0 24 25" },
  pricetag: { icon: icon_pricetag, viewBox: "0 0 31 18" },
  "intelligence-reports": { icon: icon_intelligence_reports, viewBox: "0 0 20 32" },
  "cards-for-sale": { icon: icon_cards_for_sale, viewBox: "0 0 20 32" },
  "icon-admin-A": { icon: icon_admin_a, viewBox: "0 0 28 28" },
  "icon-admin-B": { icon: icon_admin_b, viewBox: "0 0 28 28" },
  info: { icon: icon_info, viewBox: "0 0 27 28" },
  "quick-search": { icon: quick_search, viewBox: "0 0 32 32" },
  "market-pulse": { icon: market_pulse, viewBox: "-2 0 32 20" },
};

export const getDesktopHeaderItems = (universe, qs = {}) => {
  const { filters } = qs;
  const filtersqs = new URLSearchParams(Helpers.filtersToQuery(filters));
  const cardHeaderItems = [
    {
      title: "Dashboard",
      href: "/dashboard",
      iconType: "card-star",
      customIcon: true,
    },
    {
      key: "mycollection",
      title: "My Collection",
      iconType: "card-hand",
      customIcon: true,
      subItems: [
        {
          href: "/mycollection",
          title: "My Collection",
        },
        {
          href: "/mycollection/mass-import",
          title: "Mass Import",
          showMiddleware: (user) => user.is_admin || user.is_pro,
        },
        {
          href: "/mycollection/price-movements",
          title: "My Price Movements",
        },
      ],
    },
    {
      key: "charts",
      title: "Charts",
      iconType: "line-chart",
      customIcon: true,
      subItems: [
        {
          href: "/playercharts",
          title: "Popular Card Charts",
        },
        {
          href: "/livesearch",
          title: "Chart Other Cards",
        },
        {
          href: "/setyearcharts",
          title: "Set & Year Charts",
        },
      ],
    },
    {
      key: "pulse",
      title: "Market Pulse",
      iconType: "market-pulse",
      customIcon: true,
      href: "/market-pulse",
    },
    {
      key: "stats",
      title: "Stats",
      iconType: "pricetag",
      customIcon: true,
      subItems: [
        {
          href: `/playerleaderboard?${filtersqs}`,
          title: "Price Movements by Player",
        },
        {
          href: `/leaderboard?${filtersqs}`,
          title: "Price Movements by Card",
        },
        {
          href: `/playersalesvolume?${filtersqs}`,
          title: "Sales Volume by Player",
        },
        {
          href: `/salesvolume?${filtersqs}`,
          title: "Sales Volume by Card",
        },
      ],
    },
    {
      key: "cards-for-sale",
      title: "Cards For Sale",
      iconType: "cards-for-sale",
      customIcon: true,
      href: "/cards-for-sale/ebay-deal-search",
    },
    {
      key: "intelligence-reports",
      title: "Intelligence Reports",
      iconType: "intelligence-reports",
      customIcon: true,
      showMiddleware: function (user) {
        return user.is_pro || user.is_admin;
      },
      subItems: [
        {
          href: "/intelligence-reports/grade-ratios",
          title: "Grade Ratios",
        },
        {
          href: "/intelligence-reports/variation-ratios",
          title: "Variation Ratios",
        },
        {
          href: "/intelligence-reports/player-ratios",
          title: "Player Ratios",
        },
      ],
    },
    {
      key: "managealerts",
      href: "/managealerts",
      title: "Manage Alerts",
      iconType: "bell",
      customIcon: true,
    },
    {
      key: "info",
      href: "/info",
      title: "Info",
      iconType: "info",
      customIcon: true,
      subItems: [
        {
          href: "/info/faq",
          title: "FAQ's",
        },
        {
          href: "/info/updates",
          title: "New Features & Updates",
          showMiddleware: (user) => user.is_admin,
        },
        {
          href: "/info/recently-added-cards",
          title: "Recently Added Cards",
        },
        {
          href: "https://marketmoversapp.com/unlimited-member-priority-card-requests/",
          title: "Priority Card Request",
          target: "_blank",
          showMiddleware: (user) => user.is_pro,
        },
        {
          href: "/info/training-videos",
          title: "Training Videos",
        },
      ],
    },
    {
      key: "admin",
      showMiddleware: (user) => user.is_admin,
      title: "Admin",
      iconType: "icon-admin-A",
      customIcon: true,
      subItems: [
        {
          href: "/managecards",
          title: "Manage Cards",
        },
        {
          href: "/manage-index/list",
          title: "Manage Indices",
        },
        {
          href: "/excluded",
          title: "Excluded Cards",
        },
        {
          href: "/reported",
          title: "Reported Cards",
        },
        {
          href: "/appranking",
          title: "App Rankings",
        },
        {
          title: "Manage Photos",
          href: "https://marketmovers.sportscardinvestor.com/admin/master-photos?sealed=0",
          target: "_blank",
        },
        {
          href: "/manage-admin-photos",
          title: "Manage Admin Photos",
        },
        {
          href: "/reports/pop-count",
          title: "Pop. Count",
        },
        {
          href: "/reports/card-picks-wp",
          title: "Card Picks for WP",
        },
        {
          href: "/mass-import-transactions",
          title: "Mass Import Transactions",
        },
        {
          href: "/cards-under-comps",
          title: "Cards Under Comps",
        },
      ],
    },
    {
      key: "adminreports",
      showMiddleware: (user) => user.is_admin,
      title: "Reports",
      iconType: "icon-admin-B",
      customIcon: true,
      subItems: [
        {
          href: "/data-dashboard",
          title: "Data Dashboard",
        },
        {
          href: "/reports/api-call-logging",
          title: "API Call Logging",
        },
        {
          href: "/reports/cards-by-admin",
          title: "Cards by Admin",
        },
        {
          href: "/reports/master-card-extract",
          title: "Master Card Extract",
        },
        {
          href: "/active-members",
          title: "Member Report",
        },
      ],
    },
    {
      key: "technical",
      showMiddleware: (user) => user.is_admin,
      title: "Technical",
      iconType: () => <ToolOutlined style={{ fontSize: 24 }} />,
      subItems: [
        {
          href: "/activefreqtiers",
          title: "Active Freq. Tier Mgmt",
        },
        {
          href: "/errors",
          title: "Error logs",
        },
        {
          href: "/manageinfobanners",
          title: "Info Banners",
        },
        {
          href: "/jobs",
          title: "Jobs",
          render: ({ hasJobsFailedRecently }) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>Jobs</span>
              {hasJobsFailedRecently && (
                <div style={{ marginLeft: 4, width: 6, height: 6, borderRadius: "50%", backgroundColor: "red" }} />
              )}
            </div>
          ),
        },
        {
          href: "/jobs-catchup",
          title: "Jobs Catchup",
        },
        {
          href: "/lambda-jobs",
          title: "Lambda Jobs",
        },
        {
          href: "/health",
          title: "System Health",
        },
        {
          href: "/uel-settings",
          title: "UEL Settings",
        },
        {
          showMiddleware: (user) => user?.name,
          href: "/profile",
          title: (user) => user?.name || "-",
          iconType: UserOutlined,
        },
      ],
    },
  ];

  return universe === "card" ? cardHeaderItems : waxHeaderItems;
};

export const waxHeaderItems = [
  {
    title: "Dashboard",
    href: "/dashboard",
    iconType: "card-star",
    customIcon: true,
  },
  {
    key: "mycollection",
    title: "My Collection",
    iconType: "card-hand",
    customIcon: true,
    showMiddleware: (user) => isUserAtLeast(user, MembershipTiers.PREMIUM),
    subItems: [
      {
        href: "/mycollection/sealed-wax",
        title: "My Sealed Wax",
      },
    ],
  },
  {
    key: "charts",
    title: "Charts",
    iconType: "line-chart",
    customIcon: true,
    subItems: [
      {
        href: "/wax-charts",
        title: "Popular Wax Charts",
      },
      {
        href: "/sealedwaxsearch",
        title: "Chart Other Wax",
      },
    ],
  },
  {
    key: "stats",
    title: "Stats",
    iconType: "pricetag",
    customIcon: true,
    subItems: [
      {
        href: "/wax/pricemovements",
        title: "Price Movements by Box Type",
      },
    ],
  },
  {
    key: "managealerts",
    href: "/managealerts",
    title: "Manage Alerts",
    iconType: "bell",
    customIcon: true,
    subItems: [
      {
        href: "/managealerts",
        title: "Manage Alerts",
      },
    ],
  },
  {
    key: "info",
    href: "/info",
    title: "Info",
    iconType: "info",
    customIcon: true,
    subItems: [
      {
        href: "/info/faq",
        title: "FAQ's",
      },
      {
        href: "/info/updates",
        title: "New Features & Updates",
        showMiddleware: (user) => user.is_admin,
      },
      {
        href: "/info/recently-added-cards",
        title: "Recently Added Cards",
      },
      {
        href: "/info/training-videos",
        title: "Training Videos",
      },
    ],
  },
  {
    key: "admin",
    showMiddleware: (user) => user.is_admin,
    title: "Admin",
    iconType: "icon-admin-A",
    customIcon: true,
    subItems: [
      {
        href: "/manage-wax",
        title: "Manage Wax",
      },
      {
        href: "/excluded",
        title: "Excluded Wax",
      },
      {
        href: "/reported",
        title: "Reported Wax",
      },
      {
        href: "/appranking",
        title: "App Rankings",
      },
      {
        title: "Manage Wax Photos",
        href: "https://marketmovers.sportscardinvestor.com/admin/master-photos?sealed=1",
        target: "_blank",
      },
      {
        href: "/reports/card-picks-wp",
        title: "Card Picks for WP",
      },
      {
        href: "/mass-import-transactions",
        title: "Mass Import Transactions",
      },
    ],
  },
  {
    key: "adminreports",
    showMiddleware: (user) => user.is_admin,
    title: "Reports",
    iconType: "icon-admin-B",
    customIcon: true,
    subItems: [
      {
        href: "/data-dashboard",
        title: "Data Dashboard",
      },
      {
        href: "/reports/api-call-logging",
        title: "API Call Logging",
      },
      {
        href: "/reports/cards-by-admin",
        title: "Wax by Admin",
      },
      {
        href: "/reports/master-card-extract",
        title: "Master Wax Extract",
      },
    ],
  },
  {
    key: "technical",
    showMiddleware: (user) => user.is_admin,
    title: "Technical",
    iconType: () => <ToolOutlined style={{ fontSize: 24 }} />,
    subItems: [
      {
        href: "/activefreqtiers",
        title: "Active Freq. Tier Mgmt",
      },
      {
        href: "/errors",
        title: "Error logs",
      },
      {
        href: "/manageinfobanners",
        title: "Info Banners",
      },
      {
        href: "/jobs",
        title: "Jobs",
        render: ({ hasJobsFailedRecently }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>Jobs</span>
            {hasJobsFailedRecently && (
              <div style={{ marginLeft: 4, width: 6, height: 6, borderRadius: "50%", backgroundColor: "red" }} />
            )}
          </div>
        ),
      },
      {
        href: "/jobs-catchup",
        title: "Jobs Catchup",
      },
      {
        href: "/lambda-jobs",
        title: "Lambda Jobs",
      },
      {
        href: "/health",
        title: "System Health",
      },
      {
        href: "/uel-settings",
        title: "UEL Settings",
      },
      {
        showMiddleware: (user) => user?.name,
        href: "/profile",
        title: (user) => user?.name || "-",
        iconType: UserOutlined,
      },
    ],
  },
];

export const getCurrentMenu = (pathname, universe) => {
  const current = getDesktopHeaderItems(universe).find((item) => {
    if (item.href && item.href === pathname) {
      return true;
    }

    if (item.subItems) {
      return !!item.subItems.find((subItem) => subItem.href === pathname);
    }
  });

  return current ? [current.key] : [];
};
